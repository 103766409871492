











import { Component, Vue } from 'vue-property-decorator';
import VideoCard from '@/components/VideoCard.vue';
import VideosApi from '@/api/Videos';
import Video from '@/models/Video';

@Component({
  components: { VideoCard }
})
export default class Vidoes extends Vue {
  public videos: Video[] = [];

  mounted() {
    VideosApi.getAllVideos().then((videos: Video[]) => {
      this.videos = videos;
    })
  }
}









import { Component, Prop, Vue } from 'vue-property-decorator';
import Video from '@/models/Video';
import VideosApi from '@/api/Videos';
import Constants from '@/constants';

@Component
export default class VideoCard extends Vue {
  @Prop() public video: Video;
  public thumbnailUrl = '';

  mounted() {
    this.thumbnailUrl = `${Constants.api}/videos/${this.video.id}/thumbnail`;
  }

  public goToVideo() {
    this.$router.push(`/app/video-test/${this.video.id}`);
  }

}
